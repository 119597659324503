import React from 'react';
import { SecondaryHero } from '@latitude/hero';
import { Box } from '@latitude/box';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';
import { Heading2, Heading6 } from '@latitude/heading';
import { Metadata } from '@latitude/metadata';
import { Bold, Strong, Text } from '@latitude/text';
import { Vertical } from '@latitude/spacing';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { Link } from '@latitude/link';
import { List, ListItem } from '@latitude/list';

import { SITE_URL } from '../utils/constants';
import Layout from '../components/layout';
import Section from '@latitude/section';

const NavBar = [
  {
    anchor: 'privacy-and-credit',
    label: 'Privacy and Credit Reporting Policy'
  },
  {
    anchor: 'privacy-impact',
    label: 'Privacy Impact Assessment'
  }
];

//to enable smooth scrolling
const goToView = (event: Event) => {
  event.preventDefault();
  let element = document.getElementById('how-to-access-personal-information');
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'end',
    inline: 'nearest'
  });
};
const Privacy = ({ location, data }) => (
  <Layout location={location}>
    <main className="navigation-spacer">
      <Metadata
        title="Privacy &amp; Credit Reporting policy | Gem Finance"
        description="Latitude Financial Services Limited take our obligations for protecting personal information seriously. We are bound by, and will abide by, the requirements of the Privacy Act 1993."
        canonical={`${SITE_URL}${location.pathname}`}
      />
      <SecondaryHero
        heading={
          <>
            Privacy and Credit <br />
            Reporting Policy
          </>
        }
        subHeading={null}
      />

      <div className="d-none d-lg-block">
        <StickyNavigation
          items={NavBar}
          offsetElem="[data-sticky-navigation-offset]"
        />
      </div>

      <Section id="privacy-and-credit" className="bg-lightest">
        <Heading2 className="text-center">
          Privacy and Credit Reporting Policy
        </Heading2>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <PrivacySection />
          </div>
        </div>
      </Section>
      <Section id="privacy-impact">
        <Heading2 className="text-center"></Heading2>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <PrivacyImpactSection />
          </div>
        </div>
      </Section>

      <HorizontalRule
        css={`
          background-color: ${COLOR.TRANSPARENT};
        `}
      />
    </main>
  </Layout>
);

const PrivacySection = () => (
  <Box.Section backgroundColor={COLOR.WHITE} isBodyCopy>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        <Bold>Effective from 12 March 2024</Bold>
      </Text>
      <Text>
        Latitude Financial Services Limited (and our related entities)
        (&ldquo;Latitude&rdquo;), 8 Tangihua Street, Auckland Central, Auckland,
        New Zealand, take our obligations for protecting personal information
        seriously. We are bound by, and will abide by, the requirements of the
        Privacy Act 2020.
      </Text>
      <Text>
        This privacy policy sets out how we will collect, use, disclose and
        protect your personal information (&ldquo;Privacy Policy&rdquo;). You
        accept this Privacy Policy when you sign up for, access, or use our
        products or services, content, features, technologies or functions
        offered on our website or mobile apps.
      </Text>
      <Text>
        You may interact with us anonymously or by using a pseudonym if the
        interaction is general in nature. However, if the interaction is
        specific to an account or relates to your personal information, we will
        need to identify you before we can engage in further discussions and
        correspondence.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      1. Changes to this Privacy Policy
    </Heading6>
    <Text>
      We reserve the right to modify or amend this Privacy Policy at any time
      and for any reason by uploading a revised policy onto our website. The
      change to the Privacy Policy will apply from the date that we upload the
      revised policy.
    </Text>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      2. What Personal Information do we Collect and Hold
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        We offer a wide range of products and services and as a result, we
        collect and hold a range of personal information including 'biometric
        information' from and about people (particularly people who wish to
        purchase our products or use our services).
      </Text>
      <Text>
        In the process of conducting our businesses, we are likely to collect a
        broad range of information about our customers, prospective customers,
        employees, prospective employees, contractors, suppliers, brokers,
        introducers, agents, service providers and the people who run the
        businesses we deal with. This information can include such things as
        contact details, financial information and supporting documentation
        (including credit history details), identification information including
        facial biometric data (a photo/a selfie video), transaction history
        information, billing information, banking details and personal
        references. In order to satisfy our legal obligations, we may need to
        retain that information even after a transaction has come to an end
        [subject to our obligations under the Privacy Act and Anti-Money
        Laundering and Countering Financing of Terrorism Act 2009 (AML/CFT Act)
        or any other applicable Act].
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      3. How we Collect Personal Information
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        Wherever it is possible and practical to do so, we collect personal
        information directly from the person concerned. For example, we will
        collect personal information when a person deals with us in person or
        over the telephone, sends us a letter, e-mail or fax, or gives or sends
        us a completed form (such as an application form). There may be
        occasions, however, where we need to collect personal information we
        require for a particular purpose from a third party. This might occur,
        for example, where it is important for us to obtain personal information
        from an independent third party or from a Merchant who you choose to use
        one of our services or products through.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      4. Why we Collect and Hold Personal Information
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        We collect this information in order to undertake and complete the many
        transactions we have with our customers, suppliers, and other parties
        with whom we do business, to administer the products we provide and to
        maintain the business relationships we have that enable us to carry on
        our business. Personal information collected may also be used to:
      </Text>
      <List>
        <ListItem>provide you with product(s) or service(s),</ListItem>
        <ListItem>verify your identity,</ListItem>
        <ListItem>improve the services and products we provide,</ListItem>
        <ListItem>
          keeping our customers informed of the products and services we have,
          which may include using it for direct marketing purposes,
        </ListItem>
        <ListItem>
          to meet our legal and regulatory requirements under various
          legislations such as the Privacy Act 2020, Anti-Money Laundering and
          Countering Financing of Terrorism Act, Credit Contracts and Consumer
          Finance Act, any other applicable legislation or regulation.
        </ListItem>
      </List>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      5. Other Organisations we Disclose Personal Information to
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        In providing and managing our products and services, we contract with
        other businesses and may disclose personal information to them in the
        process. These organisations provide services to us and may need access
        to the personal information we hold in order to enable them to perform
        those services. We require these companies to adhere to our strict
        confidentiality requirements for handling personal information and also
        seek to ensure that they adhere to the requirements of the Privacy Act.
      </Text>
      <Text>
        We may disclose your personal information to third parties , such as
        advisors, lawyers, accountants, acquirers (or potential acquirers) of
        all or any part of our business (including our related entities within
        the Latitude Group), or resellers who sell our services, or business
        partners with whom we exchange customer data for the purposes of
        providing our services to you, including to debt collection agencies for
        the purposes of collecting debts, or for the purposes of due diligence
        enquiries or in order to run the business or part of the business
        acquired.
      </Text>
      <Text>
        We will disclose your information to any person authorised by you any
        other person authorised by the Act or another law (e.g. a law
        enforcement agency).
      </Text>
      <Text>
        Some of the third parties to which we may disclose your personal
        information may be located outside of New Zealand. Where they are
        located outside of New Zealand we ensure they will handle your Personal
        Information in accordance to the Privacy Act.
      </Text>
    </Vertical>
    <div id="how-to-access-personal-information">
      <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
        6. How to Access and Correct the Personal Information we Hold about you
      </Heading6>
      <Vertical spacing={MARGIN.TEXT}>
        <Text>
          If you wish to obtain details of your personal information that we
          hold, please contact using the 'Contact Us' table at the end of this
          policy. There is no charge to request access to your personal
          information. Subject to certain grounds, we may refuse access to your
          data, however, we will advise you the reasons why.
        </Text>
        <Text>
          If you wish to request correction to the personal information we hold,
          we will correct your personal information. If we do not make the
          correction, we will take reasonable steps to note on the personal
          information that you requested the correction.
        </Text>
        <Text>
          To correct your personal information please contact us using the table
          below. Your request should provide evidence of who you are and set out
          the details of your request (e.g. the personal information, or the
          correction, that you are requesting).
        </Text>
        <Text>
          We may charge you our reasonable costs of providing to you copies of
          your personal information.
        </Text>
      </Vertical>
    </div>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      7. How you can Make a Complaint regarding your Personal Information
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        If you believe that we have failed to comply with the Privacy Act,
        please contact us by visiting{' '}
        <a
          href="https://www.gemfinance.co.nz/complaints/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.gemfinance.co.nz/complaints/
        </a>{' '}
        and we will then follow our Internal Dispute Resolution process to
        review your concerns.
      </Text>
      <Text>
        If the complaint remains unresolved, you may refer it to Privacy
        Commissioner who will independently and impartially review and resolve
        the dispute.
      </Text>
      <Text>The contact details for the Privacy Commissioner are:</Text>
      <Text>
        <strong>Office of the Privacy Commissioner</strong>
        <br />
        PO Box 10094
        <br />
        Wellington 6143
        <br />
        Phone: 0800 803 909
        <br />
        Fax: 04 474 7595
        <br />
        Website:{' '}
        <Link
          href="http://www.privacy.org.nz"
          target="_blank"
          rel="noopener nofrefferer"
        >
          www.privacy.org.nz
        </Link>
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      8. Your Personal Information and emails
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        If you send us an e-mail we will store and make use of your name,
        address and other information about you contained in your e-mail and in
        any attachments. When you submit feedback or questions via e-mail, it is
        very important that you do not disclose any details that could be used
        by others to gain access to your account. This includes private details
        such as your username, password or card number.
      </Text>
      <Text>
        If you receive an e-mail from us, you should only re-transmit,
        distribute or commercialise the material or information in the e-mail if
        you are authorised to do so (under the Privacy Act or otherwise).
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      9. Your Personal Information on the Web
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        We do not collect personal information about you when you visit our web
        sites unless you apply for credit online or register for access to one
        of our Online Service Centres.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      10. Your Personal Information and Direct Marketing
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        By providing us with your personal information, you agree to us using
        that personal information for the purpose of informing you about our
        products and services, or providing your information to our related
        entities within the Latitude Group and third parties we have dealings
        with to market our products and services or to market their products and
        services to you. If you do not wish to receive marketing materials from
        us, please complete the opt-out provision on the marketing information
        sent to you or contact us using the table below. If you choose not to
        receive any marketing materials from us, you acknowledge you may miss
        out on special product and service offerings and opportunities.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      11. Tracking Information on the Web
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        For statistical purposes, we collect information on website activity
        such as the number of users who visit our websites, the date and time of
        visits, the number of pages viewed, navigation patterns, what country
        and what systems users have used to access the site and, when entering
        one of our websites from another website, the address of that website.
        This information is obtained through the use of &lsquo;cookies&rsquo;
        (refer below for more information about &lsquo;cookies&rsquo;).
      </Text>
      <Text>
        Our websites may also contain links to other websites. While these links
        are provided for convenience, if you are navigating these sites please
        be aware that the information handling practices of the linked websites
        may differ from ours. While we try to link only to sites that share our
        high standards and respect for privacy, we are not able to guarantee the
        privacy practices of other websites.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      12. Protecting your Personal Information
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        We will take reasonable steps to keep your personal information safe
        from loss, unauthorised activity, or other misuse. You must take care to
        protect your personal information. You should also notify us via our
        contact details listed below as soon as you become aware of any security
        breaches relating to your account.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      13. Website Security
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        Whenever personal information is sent via our website we use high
        security levels to protect it. These security levels are standard for
        internet banking and large scale e-commerce sites and involve the use
        high levels of encryption. The security level of a web page can be
        viewed by clicking on the internet browser&apos;s padlock or key icon.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      14. Cookies
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        A &lsquo;cookie&rsquo; is a packet of information that allows a website
        to identify and interact more effectively with a computer. Cookies do
        not identify you, but they may record some browser and/or connection
        information. Cookies are widely used on websites to help with
        navigation, monitor usage and to help personalise your experience when
        you visit a website. We may use cookies to monitor use of this website,
        to provide you with access to tailored information and services from
        this website, and to serve you better when you return to this website.
      </Text>
      <Text>
        Your browser can be individually set to accept all cookies, reject all
        cookies, or notify when a cookie is sent. If cookies are rejected, there
        may be limits on how our websites can be used.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      15. Website Advertisements
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        From time to time we place advertisements on other websites. This may
        involve installing a cookie on a computer when our advertisement is
        viewed. This simply allows us to keep track of how many unique visitors
        we have to our site and from what advertisements they entered.
      </Text>
      <Text>
        This then allows us to measure the effectiveness of different
        advertisements. The privacy policies of the websites which install the
        cookies should provide additional information about the use of cookies.
      </Text>
      <Text>
        To evaluate the effectiveness of our website advertising, we may use
        third parties to collect statistical data. No personal data is collected
        on these occasions.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      16. Online Service Centres
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        When you register for access to one of our Online Service Centres
        (including our mobile app), the information collected is compared with
        the details we already have stored, such as your name, date of birth and
        card number, which we have previously collected from you so we can
        verify that you are the person seeking access to your personal
        information. If you do not provide the information requested, you will
        not be able to access the Online Service Centre.
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      17. Credit Reporting Policy
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        The Credit Reporting Privacy Code permits credit providers like Latitude
        to share information with credit reporting agencies, on an ongoing
        basis. This means that Latitude may provide credit reporting agencies
        with certain credit related information about its customers’ accounts,
        such as:
      </Text>
      <List>
        <ListItem>type of credit account e.g. credit card</ListItem>
        <ListItem>amount of credit extended (but not current balance)</ListItem>
        <ListItem>
          status of account e.g. open (including account open date)
        </ListItem>
        <ListItem>
          details of credit provider i.e. the lender who provided the credit
        </ListItem>
        <ListItem>
          monthly reporting of repayment history e.g. whether an account was
          paid on time, and
        </ListItem>
        <ListItem>
          changes in personal details such as address, contact details, etc.
        </ListItem>
      </List>
      <Text>
        For further information on comprehensive credit reporting, please see
        the following website{' '}
        <Link
          href="https://www.privacy.org.nz/privacy-act-2020/codes-of-practice/crpc2020/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.privacy.org.nz/privacy-act-2020/codes-of-practice/crpc2020/
        </Link>
      </Text>
    </Vertical>
    <Heading6 marginTop={MARGIN.M24} marginBottom={MARGIN.M8}>
      18. Contact Us
    </Heading6>
    <Vertical spacing={MARGIN.TEXT}>
      <Text>
        Below are the contact numbers for the products and services offered by
        Latitude Financial Services and its related entities.
      </Text>
      <Text
        css={`
          table.contactInfo {
            border-collapse: collapse;

            th {
              border: 1px solid #777;
              background: #eee;
              padding: 2px 5px;
              font-weight: bold;
            }
            td {
              border: 1px solid #777;
              padding: 2px 5px;
            }
          }
        `}
      >
        <table style={{ width: '500px' }} className="contactInfo">
          <thead>
            <th>Product</th>
            <th>Contact Information</th>
          </thead>
          <tbody>
            <tr>
              <td>Personal Loan</td>
              <td>
                Phone: <a href="tel:0800422898">0800 422 898</a>
              </td>
            </tr>
            <tr>
              <td>Credit Cards</td>
              <td>
                Phone: <a href="tel:0800500505">0800 500 505</a>
              </td>
            </tr>
            <tr>
              <td>Genoapay</td>
              <td>
                <a
                  href="https://www.gemfinance.co.nz/complaints/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Complaints
                </a>
                <br />
                <a
                  href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=17429141012753"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Access &amp; Correction
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </Text>
    </Vertical>
  </Box.Section>
);

const PrivacyImpactSection = () => (
  <Box.Section backgroundColor={COLOR.WHITE} isBodyCopy>
    <Heading2 color={COLOR.BLUE}>Privacy Impact Assessment</Heading2>
    <ol
      css={`
        color: ${COLOR.BLUE};
      `}
    >
      <li>
        <Heading6
          marginTop={MARGIN.M24}
          marginBottom={MARGIN.M8}
          color={COLOR.BLUE}
        >
          Overview
        </Heading6>
        <Vertical spacing={MARGIN.TEXT}>
          <Text>
            The purpose of this document is to provide customers with a
            high-level overview of the privacy impact assessment completed by
            Latitude Financial Services Limited (Latitude) while adopting a
            biometric identity verification tool to on-board new customers. The
            document highlights how this tool meets Latitude’s needs, enables
            better customer outcomes while ensuring any privacy impacts have
            been considered and mitigated by Latitude and APLYiD (third party
            providing biometric identity verification service). This document
            may be amended from time to time as technology and user cases
            evolve.
          </Text>
          <Text color={COLOR.BLUE}>Effective Date</Text>
          <Text>This report is effective from 12 March 2024</Text>
        </Vertical>
      </li>
      <li>
        <Heading6
          marginTop={MARGIN.M24}
          marginBottom={MARGIN.M8}
          color={COLOR.BLUE}
        >
          The Process
        </Heading6>
        <Vertical spacing={MARGIN.TEXT}>
          <Text>
            <strong>Purpose for collection</strong> <br />
            Latitude takes its obligations for verifying an applicant against
            their claimed identity under the AML/ CFT Act 2009 (the Act) very
            seriously. The Act requires all entities to verify an applicant’s
            name, date of birth, and address against their claimed identity
            before forming a relationship with them, either electronically or
            face-to-face.
          </Text>
          <Text>
            Additionally, Latitude may also collect and verify personal
            information including facial biometric to counter identity fraud and
            minimise security risk where applicable.
          </Text>
          <Text>
            For all failed electronic identity verification (non-biometric
            check) and fraud identity verification cases, applicants are
            required to visit a retail partner for a face-to-face verification
            or provide a selfie photo holding a photo ID document with
            acceptable certified supporting documents to validate the claimed
            identity. Latitude also offers an option to complete mandatory
            AML/Fraud identity verification through the APLYiD (an external
            software service solution) biometric eIDV tool.
          </Text>
          <Text>
            The purpose of biometrics collection is essential to identify and
            verify the applicants before providing them with credit.
          </Text>
          <Text>
            <Strong>Who do we collect personal information from?</Strong> <br />
            Any credit applicant who is required to be verified under the Act
            for fraud prevention purposes or applicants who fail the Equifax
            eIDV check (non-biometric eIDV tool).
          </Text>
          <Text>
            The information is collected directly from the applicant, and
            consent is sought prior to the collection of personal information.
            Further, the collection is done in accordance with legislative
            requirements under the Privacy Act.
            <br />
          </Text>
          <Text>
            An applicant can undertake biometric electronic verification
            evaluation on their smart device through the link provided by
            Latitude.
            <br />
          </Text>
          <Text>
            <Strong>
              What personal information including biometrics is collected?
            </Strong>{' '}
            <br />
            The applicant fills out the application form to provide their full
            name, date of birth, address, and links to their government provided
            identity document. As part of the identity verification process, an
            image or video selfie of the applicant with the ID document is
            supplied. The image of the applicant’s face on the driver’s licence
            or passport is then extracted and matched with the individuals face
            within the image or video selfie supplied. Based on the matching, an
            indicative breakdown across segments of the face is generated to
            demonstrate the degree of identity match. Background sound is also
            recorded during this process, although customers are not required to
            record their voice, so no voice biometrics are collected.
          </Text>
          <Text>
            <Strong>Who will personal data be disclosed to?</Strong> <br />
            The APLYiD tool extracts data through the application form, photo or
            video of the applicant, and identity documents provided to link, and
            match claimed identity with the applicant across government data
            sources (such as New Zealand Transport Agency, Department of
            Internal Affairs) and other public trusted data sources (such as
            bureaus' databases, comprehensive credit reporting, etc.) available
            through Centrix and facial recognition. Information is not shared
            outside NZ and is not used for marketing purposes. <br /> <br />
            More information on our privacy policy and whom we disclose can be
            found here: <br />
            <br />
            APLYiD :{' '}
            <a href="https://www.aplyid.com/nz/privacy-policy">
              https://www.aplyid.com/nz/privacy-policy
            </a>{' '}
            <br />
            <br />
            Latitudes Financial Services :{' '}
            <a href="https://www.gemfinance.co.nz/privacy/">
              https://www.gemfinance.co.nz/privacy/
            </a>
          </Text>
          <Text>
            <Strong>Retention, storage, and security measures</Strong> <br />
            <Strong>APLYiD</Strong> <br />
            APLYiD retains and secures uncollected information for maximum seven
            days period prior to deletion. Data retention is done by Amazon Web
            Services (AWS) which provides data encryption in transit with HTTPS
            and SSL to avoid data use or tampering across all services. Further
            information on APLYiD’s data retention and safety measures can be
            found: :{' '}
            <a href="https://www.aplyid.com/nz/privacy-policy">
              https://www.aplyid.com/nz/privacy-policy
            </a>
          </Text>
          <Text>
            <Strong>Latitude</Strong> <br />
            Once results are available, they are retrieved by a Latitude
            representative, to download and secure in digital storage. Biometric
            information is available in pdf format with a photo and converted to
            a score-based output on biometric parameters. A short selfie video
            is also captured and stored for the purposes of identity fraud and
            verification and accessed in situations such as failed fraud
            verification cases. <br />
            <br />
            Each photo/video requires to be viewed separately to determine
            identity. It is important to note that for individuals with
            distinctive facial features such as tattoos and piercings, or those
            required to wear vestments, head coverings etc for culture reasons,
            the algorithm is programmed not to classify individuals based on
            their ancestry or culture and no such biometric data is stored.{' '}
            <br />
            <br />
            Latitude has safety measures in place to protect the sensitivity of
            biometrics. Amazon Web Services (AWS) are used with data encryption
            in transit to avoid data use or tampering for data retrieval from
            the APLYiD database. The data is stored in a digital storage system
            as per the document retention schedule with security safeguards in
            place. Only Latitude’s authorised staff can access the stored
            documents. Latitude has practices, procedures, and systems in place
            to ensure the protection of personal information we hold and store
            from unauthorised access, modification, and disclosure.
          </Text>
          <Text>
            <Strong>
              <u>Access Review</u>
            </Strong>{' '}
            <br />
            Access logs are in place which stamps when a file is accessed to
            ensure access is restricted to authorised staff.
          </Text>
          <Text>
            <Strong>
              <u>Data Loss Prevention</u>
            </Strong>{' '}
            <br />
            There are various DLP controls within the Latitude environment to
            reduce the risk of inadvertent unauthorised disclosure of personal
            information including biometric information.
          </Text>
          <Text>
            <Strong>
              <u>Staff Knowledge</u>
            </Strong>{' '}
            <br />
            Staff are trained on the sensitivity of the information collected,
            our obligations under Privacy legislation, and also process steps
            that need to be taken to ensure compliance not onIy with legislative
            and regulatory obligations, but also with Latitude’s expectations
            and our control environment.
          </Text>
          <Text>
            <Strong>
              <u>Access and correction to personal information?</u>
            </Strong>{' '}
            <br />
            Customers can also correct their personal information on the
            application before submission in the event that it is incorrectly
            captured. <br /> <br />
            Latitude follows a standard process per its Privacy Policy to
            provide access to and request corrections to personal information.
            Further information on how to access and request a correction can be
            found:&nbsp;
            <a href="#" onClick={goToView}>
              https://www.gemfinance.co.nz/privacy/
            </a>
          </Text>

          <br />
          <a
            download="AML-identity-verification.png"
            target="_blank"
            href="https://assets.latitudefinancial.com/images/aml-id-verification.png"
          >
            {' '}
            AML identity verification process flow chart
          </a>
          <a
            download="Identity-fraud-verification.png"
            target="_blank"
            href="https://assets.latitudefinancial.com/images/identity-fraud-prevention-process.png"
          >
            {' '}
            Identity fraud verification process flow chart
          </a>
        </Vertical>
      </li>
    </ol>
  </Box.Section>
);
export default Privacy;
